import { CalendarMonth, Padding } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import moment from 'moment';
import { useRef } from 'react';
import Countdown from 'react-countdown';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProjectHeight, useResponsive } from 'src/hooks';
import useProjectImage from 'src/hooks/useProjectImage';
import { routes } from 'src/utils/routes';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import ButtonMyTicket from '../button-my-ticket';
import JoinProjectButton from '../join-project-button';
import UserRole from '../user-role';
import UserStatus from '../user-status';
import classes from './project-card-main.module.sass';
import { color } from 'highcharts';
import { getLocalizationValue } from 'src/utils/common';

function ProjectCardMain({ project, fullWidth, showProjectDetail, className, help }) {
  const ref = useRef();
  const navigate = useNavigate();
  const isDesktop = useResponsive();

  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.app.roles);
  const order = useSelector((state) => state.app.order);

  const projectUuid = project.uuid;
  const isJoinedProject = user?.projectUuids?.includes(projectUuid);
  const role = roles.find((role) => role.id === user?.projectSettings?.[projectUuid]?.mainRoleId);
  const numOfPackages =
    order?.find((item) => item.projectUuid === projectUuid)?.packages?.reduce((p, c) => (p += c.count), 0) || 0;
  const price = project.price?.['643']?.priceFrom ?? 0;
  const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  const readyToAccreditate = false;

  const handleGotoProject = () => {
    navigate(routes.projectDashboard.path.replace(':projectUuid', projectUuid));
  };

  const handleGotoProjectDesc = () => {
    navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid));
  };

  const projectDates =
    moment
      .unix(project.dateStartPlan)
      .format(
        moment.unix(project.dateStartPlan).isSame(moment.unix(project.dateFinishPlan), 'month') ? 'D' : 'D MMMM'
      ) +
    ' - ' +
    moment.unix(project.dateFinishPlan).format('D MMMM');

  window.project = project;
  return (
    <Card
      variant="outlined"
      sx={{ borderColor: '#D1D1D1' }}
      className={classNames(classes.projectCard, className)}
      ref={ref}
    >
      <Box sx={{ height: '180px' }}>
        <CardHeader
          title={projectDates}
          sx={{ backgroundColor: '#E1E2EB', height: '36px', borderBottom: '1px solid #D1D1D1' }}
          titleTypographyProps={{ sx: { fontSize: '15px !important', fontWeight: '700 !important', color: '#737791' } }}
          className={classNames(classes.cardHeader, className)}
        />
        <CardContent className={classNames(classes.cardContent, className)}>
          <Stack className={classNames(classes.projectMainInfo, className)}>
            {project.photos?.mobile_small?.[0] && (
              <LazyLoad>
                <Box>
                  <img
                    className={classNames(classes.projectImage, className)}
                    src={project.photos?.mobile_small?.[0]}
                    width={50}
                    height={50}
                  />
                </Box>
              </LazyLoad>
            )}
            <Typography
              className={classNames(
                project.localization[lang].fullName.length > 75 ? classes.projectNameSmall : classes.projectName,
                className
              )}
            >
              {project.localization[lang].fullName}
            </Typography>
          </Stack>
          <Box>
            <Typography className={classNames(classes.projectPlace, className)}>
              Санкт-Петербург, Экспофорум, F, G, H, пассаж, конгресс-центр
            </Typography>
          </Box>
          <Typography className={classNames(classes.projectOrg, className)}>ЭкспоФорум-Интернэшнл</Typography>
        </CardContent>
      </Box>
      <CardActions sx={{ padding: '0 10px' }}>
        {!!role ? (
          <Button
            size="small"
            fullWidth
            variant="outlined"
            sx={{ border: '1x solid #737791' }}
            onClick={handleGotoProject}
          >
            Открыть проект
          </Button>
        ) : (
          project.isArchived ?? (
            <Button
              size="small"
              fullWidth
              variant="outlined"
              sx={{ border: '0', backgroundColor: '#E1E2EB !important', textTransform: 'lowercase' }}
            >
              Принять участие
            </Button>
          )
        )}
        {project.localization[lang].comment && (
          <Button
            size="small"
            fullWidth
            variant="outlined"
            sx={{ border: '1px solid #737791' }}
            onClick={handleGotoProjectDesc}
          >
            Описание
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default ProjectCardMain;
