export const routes = {
  login: { path: '/login', auth: false },
  loginExternal: { path: '/login/external', auth: false },
  forgotPassword: { path: '/forgot-password', auth: false },
  register: { path: '/register', auth: false },
  profile: { path: '/profile', auth: true },
  projectDetail: { path: '/projects/:projectUuid', auth: true, canChangeCurrentProject: true },
  projectAccreadition: { path: '/projects/accreadition/:projectUuid', auth: true, canChangeCurrentProject: true },
  projectBuyTicket: { path: '/projects/buy-ticket/:projectUuid', auth: true, canChangeCurrentProject: true },
  projectVizits: { path: '/projects/vizits/:projectUuid', auth: true, canChangeCurrentProject: true },
  projectNetworkings: { path: '/projects/networkings/:projectUuid', auth: true, canChangeCurrentProject: true },
  projectBusinessProgram: {
    path: '/projects/business-program/:projectUuid',
    auth: true,
    canChangeCurrentProject: true,
  },
  myOrder: { path: '/projects/orders/:projectUuid', auth: true, canChangeCurrentProject: true },
  archivedOrders: { path: '/projects/archived-orders/:projectUuid', auth: true },
  orderDetail: { path: '/order/:projectUuid', auth: true, canChangeCurrentProject: true },
  homepage: { path: '/projects', auth: true, disableChangeProject: true },
  paymentFail: { path: '/payment/fail', auth: true },
  paymentSuccess: { path: '/payment/success', auth: true },
  faqs: { path: '/faqs', auth: true },
  myTicket: { path: '/my-ticket/:userUuid/:projectUuid', auth: true },
  usersVerify: { path: '/users/verify/:userUuid', auth: true, isPublic: true },
  publicVizit: { path: '/public/vizit', isPublic: true },
  vCard: { path: '/v-card', isPublic: true },
  projectChat: { path: '/projects/:projectUuid/chat', auth: true },
  selfRegistration: { path: '/self-registration', isPublic: true },
  // projectCalendarMenu: { path: '/projects/calendar/:projectUuid', auth: true },
  projectInfoFiles: { path: '/projects/files/:projectUuid', auth: true },
  projectDistribute: { path: '/projects/distribute/:projectUuid', auth: true },
  projectUserSchedule: { path: '/projects/schedule/:projectUuid', auth: true },
  projectBooking: { path: '/projects/booking/:projectUuid', auth: true },
  projectBookingDetail: { path: '/projects/booking-detail/:projectUuid', auth: true },
  projectDashboard: { path: '/projects/projectdashboard/:projectUuid', auth: true },
  calendar: { path: '/projects/calendar/:projectUuid', auth: true },
  projects: { path: '/projects', auth: true },
};
