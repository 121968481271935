import { CardContent, Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { projectsAPIs } from '../../services';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import { BackHand, DownloadDone } from '@mui/icons-material';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import { routes } from 'src/utils/routes';
import { downloadFile } from 'src/utils/request';
import { createNotification } from 'src/utils/notifications';
import { getMyTicket } from 'src/store/actions/app';

const ProjectTimeline = ({ isAccredited, readyToAccreditate }) => {
  const navigate = useNavigate();

  const { projectUuid: id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const roles = useSelector((state) => state.app.roles);
  const role = roles.find((role) => role.id === user?.projectSettings?.[id]?.mainRoleId);

  const getStep = (isAccredited, readyToAccreditate, lang) => {
    switch (isAccredited) {
      case false:
        return 0;
      case true:
        return 3;
      default:
        switch (readyToAccreditate) {
          case true:
            return 2;
          default:
            return 0;
        }
    }
  };

  const [step, setStep] = useState(getStep(isAccredited, readyToAccreditate)); // Пока проверка isaccreditated или нет
  const steps = ['created', 'check', 'accreditated', 'ticket'];

  const handleGotoMyTicket = async (e) => {
    if (!user.userUuid) return;
    e.stopPropagation();
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await getMyTicket(user.userUuid, id, true);
      downloadFile(response, 'Билет');
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Timeline
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color={step >= 1 ? 'success' : 'primary'}>
            <DownloadDone />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h7" component="span">
            Подача регистрационной анкеты
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={step >= 2 ? 'success' : 'primary'}>
            <DownloadDone />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h7" component="span">
            Cтатус: На проверке
          </Typography>

          <Stack direction="column" spacing={1} alignItems={'start'}>
            <Chip
              icon={step >= 2 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              size="small"
              label="Деловая программа"
              color={step >= 2 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={
                step >= 2 ? () => navigate(routes.projectBusinessProgram.path.replace(':projectUuid', id)) : null
              }
            />
            <Chip
              icon={step >= 2 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              size="small"
              label="Экспоненты"
              color={step >= 2 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={step >= 2 ? () => null : null}
            />
            <Chip
              icon={step >= 2 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              size="small"
              label="Материалы"
              color={step >= 2 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={step >= 2 ? () => navigate(routes.projectInfoFiles.path.replace(':projectUuid', id)) : null}
            />
          </Stack>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={step >= 3 ? 'success' : 'primary'}>
            <DownloadDone />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h7" component="span">
            Cтатус: Утвержден
          </Typography>
          <Stack direction="column" spacing={1} alignItems={'start'}>
            <Chip
              icon={step >= 3 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              label="Биржа деловых контактов"
              color={step >= 3 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={step >= 3 ? () => null : null}
            />
            <Chip
              icon={step >= 3 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              label="Мой список участников"
              color={step >= 3 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={
                step >= 3
                  ? () => navigate(routes.projectAccreadition.path.replace(':projectUuid', id)) + '?tab=members'
                  : null
              }
            />
            <Chip
              icon={step >= 3 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              label="Приобритение пакетов"
              color={step >= 3 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={step >= 3 ? () => navigate(routes.projectBuyTicket.path.replace(':projectUuid', id)) : null}
            />
          </Stack>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color={step >= 4 ? 'success' : 'primary'}>
            <DownloadDone />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h7" component="span">
            Выдан электронный билет
          </Typography>
          <Stack direction="column" spacing={1} alignItems={'start'}>
            <Chip
              icon={step >= 4 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              label="Распечатайте билет"
              color={step >= 4 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
              onClick={step >= 4 ? handleGotoMyTicket : null}
            />
            <Chip
              icon={<OutlinedFlagIcon />}
              label="Посетите площадку"
              color={step >= 4 ? 'success' : 'primary'}
              variant="outlined"
              sx={{ width: '230px', justifyContent: 'flex-start', paddingLeft: '5px' }}
            />
          </Stack>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default ProjectTimeline;
