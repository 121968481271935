import { Box, CardContent, Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { projectsAPIs, userAPIs } from '../../services';
import ProjectTimeline from './ProjectTimeline';
import LazyLoad from 'react-lazyload';
import classes from './projectDashboard.module.sass';
import moment from 'moment';
import classNames from 'classnames';
import { useLanguage } from 'src/hooks';
import { useCurrentProject, useProjectData } from 'src/hooks/project';
import { Help, HelpRounded, QuestionMarkRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserStatus from 'src/components/user-status';
import { routes } from 'src/utils/routes';
import UserRole from 'src/components/user-role';
import VizitStatus from 'src/components/status/VizitStatus';

const ProjectDashboard = ({}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.app.roles);
  const { projectUuid: id } = useParams();
  const role = roles.find((role) => role.id === user?.projectSettings?.[id]?.mainRoleId);
  const isAccredited = user?.projectSettings?.[id]?.isAccredited;

  const readyToAccreditate = user?.projectSettings?.[id]?.readyToAccreditate;

  const [vizits, setVizits] = useState(null);

  const fetchVizit = async () => {
    try {
      const vizitsRes = await userAPIs.getUserVizitsProject(id);

      setVizits(vizitsRes.message);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id) {
      fetchVizit();
    }
  }, [id]);

  const { isLoading, data: project } = useCurrentProject({ projectUuid: id });

  const projectDates =
    project != null
      ? moment.unix(project.dateStartPlan).format('D MMMM') +
        ' - ' +
        moment.unix(project.dateFinishPlan).format('D MMMM')
      : '';

  const { lang, localizeMessage, localizeText, getLocalizationValue } = useLanguage();

  return isLoading ? (
    <></>
  ) : (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack className={classNames(classes.projectMainInfo)} flexDirection={'row'}>
          {/* {project.photos?.mobile_small?.[0] && (
            <LazyLoad>
              <Box>
                <img
                  className={classNames(classes.projectImage)}
                  src={project.photos?.mobile_small?.[0]}
                  width={100}
                  height={100}
                />
              </Box>
            </LazyLoad>
          )} */}
          <Stack flexDirection={'column'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <Typography className={classNames(classes.projectName)} sx={{ fontWeight: 'bold' }}>
              {project.localization[lang].fullName}
            </Typography>
            <Typography className={classNames(classes.projectName)}>{projectDates}</Typography>
          </Stack>
        </Stack>
        <Stack direction={'column'} gap={5} flexWrap={1}>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems="center" gap={2}>
            <Typography>Роль участника:</Typography>
            <HelpRounded></HelpRounded>
            {!!role ? (
              <UserRole
                role={role}
                sx={{ alignItems: 'center', display: 'flex', width: '150px' }}
                icon={<CheckCircleIcon />}
              />
            ) : (
              <Typography sx={{ width: '150px', textAlign: 'center' }}>Нет данных</Typography>
            )}
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems="center" gap={2}>
            <Typography>Статус аккредитации:</Typography>
            <HelpRounded alignItems="center"></HelpRounded>
            <Stack direction="row" gap="10px" alignItems="center">
              <UserStatus
                isAccredited={isAccredited}
                readyToAccreditate={readyToAccreditate}
                sx={{ height: '30px', alignItems: 'center', display: 'flex', width: '150px' }}
                project={id}
                onClick={
                  isAccredited ? null : () => navigate(routes.projectAccreadition.path.replace(':projectUuid', id))
                }
              />
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems="center" gap={2}>
            <Typography>Статус визитки:</Typography>
            <HelpRounded></HelpRounded>
            <VizitStatus status={vizits?.status ? vizits?.status : 'initial'} sx={{ width: '150px' }} />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <ProjectTimeline isAccredited={isAccredited} readyToAccreditate={readyToAccreditate} />
        {/* <Box
          container
          sx={{
            marginTop: '25px',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            padding: '10px',
          }}
        >
          <h2 style={{ margin: '0 10px 10px 10px' }}>{localizeText.ABOUT_THE_EVENT}</h2>
          <Box sx={{ padding: '0 10px ' }}>
            <div className={classes.desc}>
              <div
                style={{ whiteSpace: 'break-spaces' }}
                dangerouslySetInnerHTML={{ __html: getLocalizationValue(project, 'comment') }}
              />
            </div>
          </Box>
        </Box> */}
      </Stack>
    </Stack>
  );
};

export default ProjectDashboard;
